import { Plan, Track } from '../@types'

const production = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'

const rxOffers = [
  'Virtual Visit w/ Licensed Physician',
  'Unlimited messaging w/ Physician & Care Team',
  'Prescription for Weight Loss Medication',
  '1:1 Coaching w/ a Registered Dietitian',
  'Personalized Meal Plan w/ Grocery Lists',
]

const nutritionOffers = [
  '1:1 Coaching w/ a Registered Dietitian',
  'Personalized Meal Plan w/ Grocery Lists',
  'Access to Private Community',
]

export const behaviorPlans: Plan[] = [
  {
    id: 'PT1-1-MO-C',
    sku: 'PT1-1-MO-C',
    duration: 1,
    amount: 5200,
    name: '1 Month Plan',
    line_item: 'Measured Nutrition - 1 Month',
    billed: 'monthly',
    stripe_product_id: production
      ? 'prod_M7qpNjiNfeyktJ'
      : 'prod_LxIkgNJzt7ym8D',
    stripe_price_id: production
      ? 'price_1M4XHKBS2APHTgAqmwA8fS7W'
      : 'price_1M4XG7BS2APHTgAq0avk4f0V',
    deal: null,
    billed_long: 'Billed every month',
    originalPrice: 52,
    salePrice: 52,
    productName: '1 Month Plan',
    productNameLong: '1 Month Plan',
    showMarkdownPrice: true,
    showSavedPercent: false,
    showSpecialOffer: null,
    showBonus: false,
    bonus: null,
    offers: nutritionOffers,
    track: Track.BEHAVIORAL,
  },
  {
    id: 'PT1-3-MO-C',
    sku: 'PT1-3-MO-C',
    duration: 3,
    originalPrice: 156,
    salePrice: 72,
    amount: 7200,
    name: '3 Month Plan',
    line_item: 'Measured Nutrition - 3 Months',
    billed: 'quarterly',
    stripe_product_id: production
      ? 'prod_M7qpNjiNfeyktJ'
      : 'prod_LxIkgNJzt7ym8D',
    stripe_price_id: production
      ? 'price_1M4XHbBS2APHTgAqIDUi7ZQa'
      : 'price_1M4XGYBS2APHTgAq8lNewsSP',
    deal: 'Limited Time Offer',
    billed_long: 'Billed every 3 months',
    productName: '3 Month Plan',
    productNameLong: '3 Month Plan',
    showMarkdownPrice: true,
    showSavedPercent: false,
    showSpecialOffer: 'Special',
    showBonus: false,
    bonus: null,
    offers: nutritionOffers,
    track: Track.BEHAVIORAL,
  },
  {
    id: 'PT1-12-MO-C',
    sku: 'PT1-12-MO-C',
    duration: 12,
    originalPrice: 624,
    salePrice: 192,
    amount: 19200,
    name: '12 Month Plan',
    line_item: 'Measured Nutrition - 12 Months',
    billed: 'annually',
    stripe_product_id: production
      ? 'prod_M7qpNjiNfeyktJ'
      : 'prod_LxIkgNJzt7ym8D',
    stripe_price_id: production
      ? 'price_1M4XHuBS2APHTgAqsEuPmnL1'
      : 'price_1M4XGuBS2APHTgAqa1afF5au',
    deal: 'Best Deal',
    billed_long: 'Billed every year',
    productName: '12 Month Plan',
    productNameLong: '12 Month Plan',
    showMarkdownPrice: true,
    showSavedPercent: true,
    showSpecialOffer: 'Best Deal',
    showBonus: false,
    bonus: null,
    offers: nutritionOffers,
    track: Track.BEHAVIORAL,
  },
]
export const medicalPlans: Plan[] = [
  {
    id: 'PT1-1-MO-RX',
    sku: 'PT1-1-MO-RX',
    duration: 1,
    amount: 5200,
    name: '1 Month Plan',
    line_item: 'Measured Rx - 1 Month',
    billed: 'monthly',
    stripe_product_id: production
      ? 'prod_M7qsvnHYAVlcSk'
      : 'prod_LxIjQTNqtOxExC',
    stripe_price_id: production
      ? 'price_1M4X6RBS2APHTgAqL6S6rbgi'
      : 'price_1M4XAtBS2APHTgAqvIuH2dUv',
    deal: null,
    billed_long: 'Billed every month',
    originalPrice: 52,
    salePrice: 52,
    productName: '1 Month Plan (Rx)',
    productNameLong: '1 Month Plan (Rx)',
    showMarkdownPrice: true,
    showSavedPercent: false,
    showSpecialOffer: null,
    showBonus: false,
    bonus: null,
    offers: rxOffers,
    track: Track.MEDICAL,
  },
  {
    id: 'PT1-3-MO-RX',
    duration: 3,
    originalPrice: 156,
    salePrice: 72,
    amount: 7200,
    name: '3 Month Plan',
    line_item: 'Measured Rx - 3 Months',
    billed: 'quarterly',
    stripe_product_id: production
      ? 'prod_M7qsvnHYAVlcSk'
      : 'prod_LxIjQTNqtOxExC',
    stripe_price_id: production
      ? 'price_1M4X6yBS2APHTgAqljynRMyu'
      : 'price_1M4XBKBS2APHTgAqagShFiQq',
    deal: 'Limited Time Offer',
    billed_long: 'Billed every 3 months',
    sku: 'PT1-3-MO-RX',
    productName: '3 Month Plan (Rx)',
    productNameLong: '3 Month Plan (Rx)',
    showMarkdownPrice: true,
    showSavedPercent: false,
    showSpecialOffer: 'Special',
    showBonus: false,
    bonus: null,
    offers: rxOffers,
    track: Track.MEDICAL,
  },
  {
    id: 'PT1-12-MO-RX',
    duration: 12,
    originalPrice: 624,
    salePrice: 192,
    amount: 19200,
    name: '12 Month Plan',
    line_item: 'Measured Rx - 12 Months',
    billed: 'annually',
    stripe_product_id: production
      ? 'prod_M7qsvnHYAVlcSk'
      : 'prod_LxIjQTNqtOxExC',
    stripe_price_id: production
      ? 'price_1M4X7VBS2APHTgAqvo043TsU'
      : 'price_1M4XCvBS2APHTgAqnaVeFCMt',
    deal: 'Best Deal',
    billed_long: 'Billed every 12 months',
    sku: '12-MO-RX',
    productName: '12 Month Plan (Rx)',
    productNameLong: '12 Month Plan (Rx)',
    showMarkdownPrice: true,
    showSavedPercent: true,
    showSpecialOffer: 'Best Deal',
    showBonus: false,
    bonus: null,
    offers: rxOffers,
    track: Track.MEDICAL,
  },
]

const plans = { medicalPlans, behaviorPlans }

export const defaultPlan = (track: Track) =>
  track === 'medical' ? plans.medicalPlans[2] : plans.behaviorPlans[2]

export default plans
